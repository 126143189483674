angular.module('fingerink')
    .directive('bonusCard', function () {
        return {
            restrict: 'E',
            replace: 'false',
            templateUrl: 'web/main/buy/directives/bonusCard.tpl.html',
            scope: {
                coin: '=',
                price: '=',
                producto: '='
            },
            controllerAs: 'controller',
            controller: 'bonusCardCtrl'
        };
    })
    .controller('bonusCardCtrl', function ($rootScope, $scope) {
        var that = this;
        that.slider = {
            floor: 1,
            ceil: 20,
            showTicks: true,
            hideLimitLabels: true

        };
    });
